interface ColumnSettings {
  name: string;
}

export enum MetricColumnDataType {
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency',
}

export enum DimensionColumnDataType {
  DATE = 'date',
  TEXT = 'text',
  URL = 'url',
  MEDIA = 'media',
}

export type ColumnDataType = DimensionColumnDataType | MetricColumnDataType;

export enum ColumnType {
  DIMENSION = 'dimension',
  METRIC = 'metric',
}

export enum DimensionDataFormatType {
  DATE = 'date',
  STRING = 'string',
}

export enum MetricDataFormatType {
  INTEGER = 'integer',
  NUMERIC = 'numeric(18,2)',
}

interface ColumnBaseConfig {
  group: string;
  name: string;
  description: string;
  order: number;
  isAdvancedSortable: boolean;
}
type MetricColumn = ColumnBaseConfig & {
  type: MetricColumnDataType;
  dataType: MetricDataFormatType;
};

type DimensionColumn = ColumnBaseConfig & {
  type: DimensionColumnDataType;
  dataType: DimensionDataFormatType;
};

export type ColumnsConfig = Record<string, MetricColumn | DimensionColumn>;

export interface ColumnsMeta {
  defaultColumns: {
    dimensions: ColumnSettings[];
    metrics: ColumnSettings[];
  };
  config: ColumnsConfig;
  dimensions: string[];
  metrics: string[];
}
