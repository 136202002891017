import type { ColumnsConfig, ColumnsMeta } from '@/entities/columnsConfig';
import { baseApi, getErrorMessage } from '@/shared/api';
import type {
  ColumnsMetaDTO,
  DataResponse,
  GetDataParams,
  GetMetricsMinMaxParams,
  GetNumberFilterParams,
  GetStringFilterParams,
  GetTotalParams,
  GetTreeDataParams,
  TotalResponse,
  TreeDataResponse,
} from './types';

export const getData = async ({
  dimensions,
  metrics,
  dateScale,
  dateRange,
  offset,
  sort,
  probabilisticAttribution,
  lifetime,
  dimensionsFilters,
  metricsFilters,
  pageSize,
}: GetDataParams) => {
  const { data, error } = await baseApi.functions.invoke<DataResponse>(
    'getCohortMarketingPerformanceRows',
    {
      body: {
        columns: [...dimensions, ...metrics],
        selectedDimensions: dimensions,
        dateScale,
        dateRange,
        offset,
        sort,
        probabilisticAttribution,
        lifetime,
        dimensionsFilters,
        metricsFilters,
        pageSize,
      },
    },
  );

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return data;
};

export const getTotal = async ({
  dimensions,
  metrics,
  dimensionsFilters,
  lifetime,
  dateRange,
  probabilisticAttribution,
}: GetTotalParams) => {
  const { data, error } = await baseApi.functions.invoke<TotalResponse>(
    'getCohortMarketingPerformanceTotal',
    {
      body: {
        columns: [...dimensions, ...metrics],
        selectedDimensions: dimensions,
        dateRange,
        probabilisticAttribution,
        lifetime,
        dimensionsFilters,
      },
    },
  );

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return data.total;
};

export const getTreeData = async ({
  dimensions,
  metrics,
  dateScale,
  dateRange,
  sort,
  probabilisticAttribution,
  lifetime,
  dimensionsFilters,
  metricsFilters,
  parentGroups,
}: GetTreeDataParams) => {
  const { data, error } = await baseApi.functions.invoke<TreeDataResponse>(
    'getCohortMarketingPerformanceTreeViewData',
    {
      body: {
        columns: [...dimensions, ...metrics],
        selectedDimensions: dimensions,
        dateScale,
        dateRange,
        sort,
        probabilisticAttribution,
        lifetime,
        dimensionsFilters,
        metricsFilters,
        parentGroups,
      },
    },
  );

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return data;
};

export const getColumnsMeta = async (): Promise<ColumnsMeta> => {
  const { data, error } =
    await baseApi.functions.invoke<ColumnsMetaDTO>('getBaseViewColumns');

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return {
    defaultColumns: data.defaultColumns,
    config: Object.fromEntries(
      Object.entries(data.config).map(([key, value]) => {
        return [
          key,
          {
            group: value.ui_column_group,
            name: value.ui_column_name,
            description: value.ui_column_description,
            type: value.ui_column_format,
            order: value.ui_base_view_drawer_filter_order,
            dataType: value.ui_column_data_type,
            isAdvancedSortable: value.ui_is_advanced_sorting_available,
          },
        ];
      }),
    ) as ColumnsConfig,
    dimensions: data.dimensions,
    metrics: data.metrics,
  };
};

export const getNumberFilterParams = async (params: GetNumberFilterParams) => {
  const { data, error } = await baseApi.functions.invoke<{
    min: number;
    max: number;
  }>('getNumberFilterParams', {
    body: {
      filterName: params.filterName,
      selectedDimensions: params.selectedDimensions,
      dimensionsFilters: params.dimensionsFilters.length
        ? params.dimensionsFilters
        : null,
      metricsFilters: params.metricsFilters.length
        ? params.metricsFilters
        : null,
      dateRange: params.dateRange,
      dateScale: params.dateScale,
      lifetime: params.lifetime,
      probabilisticAttribution: params.probabilisticAttribution,
    },
  });

  if (!data) {
    throw new Error(getErrorMessage(error));
  }

  return data;
};

export const getStringFilterParams = async (params: GetStringFilterParams) => {
  const filtersWithoutCurrent = params.dimensionsFilters.filter((filter) => {
    return filter.id !== params.filterName;
  });

  const { data, error } = await baseApi.functions.invoke<{
    options: string[];
  }>('getStringFilterParams', {
    body: {
      filterName: params.filterName,
      dimensionsFilters: filtersWithoutCurrent.length
        ? filtersWithoutCurrent
        : null,
      dateRange: params.dateRange,
      orderMetric: '',
      sort: null,
    },
  });

  if (!data) {
    throw new Error(getErrorMessage(error));
  }

  return data;
};

export const getMetricsMinMax = async (params: GetMetricsMinMaxParams) => {
  const { data, error } = await baseApi.functions.invoke<
    Record<
      string,
      {
        min: number;
        max: number;
      }
    >
  >('getBaseViewMetricsMinMax', {
    body: {
      metricNames: params.metricNames,
      selectedDimensions: params.selectedDimensions,
      dimensionsFilters: params.dimensionsFilters.length
        ? params.dimensionsFilters
        : null,
      metricsFilters: params.metricsFilters.length
        ? params.metricsFilters
        : null,
      dateRange: params.dateRange,
      dateScale: params.dateScale,
      probabilisticAttribution: params.probabilisticAttribution,
      lifetime: params.lifetime,
    },
  });

  if (!data) {
    throw new Error(getErrorMessage(error));
  }

  return data;
};
