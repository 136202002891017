import { isDevelopment } from '../utils';

const noop = () => {};

export class Analytics {
  static get dispatcher() {
    return !isDevelopment() && window.gtag ? window.gtag : noop;
  }

  static sendFeatureUsage(
    feature: string,
    action: string,
    details: { [key: string]: string } = {},
  ) {
    Analytics.dispatcher('event', 'feature_usage', {
      feature,
      action,
      ...details,
    });
  }
}
