import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect } from 'react';
import { formateCurrency, formatPercentage } from '@/shared/lib';
import { isFinished } from '@/shared/lib/dataFetching';
import type { NumberFilterParams, NumberFilterValue } from '../../model/types';
import { FilterType } from '../../model/types';
import type { InputComponentProps } from '../../types';
import { CurrencyInput } from '../CurrencyInput/CurrencyInput';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import { NumberInput } from '../NumberInput/NumberInput';
import { PercentageInput } from '../PercentageInput/PercentageInput';
import { Slider } from '../Slider/Slider';

export const DEFAULT_PARAMS = {
  min: 0,
  max: 1,
};

interface Props {
  id: string;
  type: FilterType;
  value?: NumberFilterValue;
  onChange: (v: NumberFilterValue) => void;
}

export function NumberFilter({ id, type, value, onChange }: Props) {
  const { paramsData, requestNumberFilterParams } = useFiltersParamsContext();
  const filterParamsLoadable = paramsData[id];
  const params = isFinished(filterParamsLoadable?.status)
    ? (filterParamsLoadable.params as NumberFilterParams)
    : DEFAULT_PARAMS;
  const DEFAULT_VALUE: NumberFilterValue = [
    params.min ?? DEFAULT_PARAMS.min,
    params.max ?? DEFAULT_PARAMS.max,
  ];
  const valueOrDefault = value || DEFAULT_VALUE;

  useEffect(() => {
    requestNumberFilterParams(id);
  }, []);

  if (!filterParamsLoadable) {
    return null;
  }

  const handleFromChange = (newFrom: number) => {
    if (newFrom === valueOrDefault[0]) {
      return;
    }

    onChange([newFrom, valueOrDefault[1]]);
  };
  const handleToChange = (newTo: number) => {
    if (newTo === valueOrDefault[1]) {
      return;
    }

    onChange([valueOrDefault[0], newTo]);
  };
  const handleRangeChange = (newValue: [number, number]) => {
    onChange(newValue);
  };

  const { step, formatter, Input } = getInputComponentConfig(type);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
        position: 'relative',
      }}
    >
      <Box display="flex" gap={5}>
        <Box
          sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '50%' }}
        >
          <Typography color="secondary" variant="subtitle2">
            From
          </Typography>
          <Input
            min={params.min}
            step={step}
            max={params.max}
            value={valueOrDefault[0]}
            onChange={handleFromChange}
          />
        </Box>
        <Box
          sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '50%' }}
        >
          <Typography color="secondary" variant="subtitle2">
            To
          </Typography>
          <Input
            step={step}
            min={params.min}
            max={params.max}
            value={valueOrDefault[1]}
            onChange={handleToChange}
          />
        </Box>
      </Box>
      <Slider
        min={params.min}
        max={params.max}
        step={step}
        formatter={formatter}
        value={valueOrDefault}
        onChange={handleRangeChange}
      />
    </Box>
  );
}

interface InputConfig {
  step: number;
  formatter: (v: number) => string;
  Input: FC<InputComponentProps>;
}

const getInputComponentConfig = (type: FilterType): InputConfig => {
  if (type === FilterType.CURRENCY) {
    return {
      step: 0.01,
      formatter: formateCurrency,
      Input: CurrencyInput,
    };
  }

  if (type === FilterType.PERCENTAGE) {
    return {
      step: 0.01,
      formatter: formatPercentage,
      Input: PercentageInput,
    };
  }

  return {
    step: 1,
    formatter: (v) => {
      return v.toLocaleString();
    },
    Input: NumberInput,
  };
};
