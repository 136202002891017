import { Box, Divider } from '@mui/material';
import { useMemo } from 'react';
import {
  resetFilters,
  selectDateRange,
  selectDateScale,
  selectFilters,
  selectMetaAllDimensions,
  selectMetaAllMetrics,
  updateDateRange,
  updateDateScale,
  updateFilters,
  selectMetaConfig,
  getNumberFilterParams,
  getStringFilterParams,
  updateLifetime,
  selectLifetime,
  selectConfigDimensions,
  selectProbabilisticAttribution,
} from '@/widgets/cohortMarketingPerformance';
import type { Filters } from '@/features/filters';
import {
  FiltersDrawer,
  FiltersProvider,
  QuickFilters,
  ResetAllButton,
  FiltersParamsProvider,
  QuickFiltersProvider,
} from '@/features/filters';
import { LifetimeSelect } from '@/features/LifetimeSelect';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import type {
  DateScale,
  Lifetime,
  SerializedDateRangeValueObject,
} from '@/shared/types';
import { DateRangeSelect, DateScaleSelect } from '@/shared/ui';

export function CohortMarketingPerformanceFilters() {
  const dispatch = useAppDispatch();
  const allDimensions = useAppSelector(selectMetaAllDimensions);
  const allMetrics = useAppSelector(selectMetaAllMetrics);
  const columnsConfig = useAppSelector(selectMetaConfig);
  const dateScale = useAppSelector(selectDateScale);
  const dateRange = useAppSelector(selectDateRange);
  const lifetime = useAppSelector(selectLifetime);
  const filters = useAppSelector(selectFilters);
  const selectedDimensions = useAppSelector(selectConfigDimensions);
  const probabilisticAttribution = useAppSelector(
    selectProbabilisticAttribution,
  );
  const numberFilterParamsDependencies = useMemo(() => {
    return [
      selectedDimensions,
      filters,
      allMetrics,
      allDimensions,
      dateRange,
      dateScale,
      probabilisticAttribution,
      lifetime,
    ];
  }, [
    selectedDimensions,
    filters,
    allMetrics,
    allDimensions,
    dateRange,
    dateScale,
    probabilisticAttribution,
    lifetime,
  ]);
  const stringFilterParamsDependencies = useMemo(() => {
    return [filters, allMetrics, allDimensions, dateRange];
  }, [filters, allMetrics, allDimensions, dateRange]);

  const handleDateScaleChange = (newScale: DateScale) => {
    dispatch(updateDateScale(newScale));
  };
  const handleDateRangeChange = (
    newDateRange: SerializedDateRangeValueObject,
  ) => {
    dispatch(updateDateRange(newDateRange));
  };
  const handleLifetimeChange = (newLifetime: Lifetime) => {
    dispatch(updateLifetime(newLifetime));
  };
  const handleGetNumberFilterParams = (id: string) => {
    return dispatch(
      getNumberFilterParams({
        filterName: id,
        selectedDimensions,
        filters,
        allMetrics,
        allDimensions,
        dateRange,
        dateScale,
        probabilisticAttribution,
        lifetime,
      }),
    );
  };
  const handleGetStringFilterParams = (id: string) => {
    return dispatch(
      getStringFilterParams({
        filterName: id,
        filters,
        allMetrics,
        allDimensions,
        dateRange,
      }),
    );
  };
  const handleFiltersChange = (newFilters: Filters) => {
    dispatch(updateFilters(newFilters));
  };
  const handleResetAllClick = () => {
    dispatch(resetFilters());
  };

  return (
    <Box display="flex" gap={1.25}>
      <DateScaleSelect value={dateScale} onChange={handleDateScaleChange} />
      <DateRangeSelect value={dateRange} onChange={handleDateRangeChange} />
      <Divider flexItem orientation="vertical" />
      <LifetimeSelect value={lifetime} onChange={handleLifetimeChange} />
      <Box flexGrow={1} />
      <FiltersParamsProvider
        allDimensions={allDimensions}
        allMetrics={allMetrics}
        columnsConfig={columnsConfig}
        numberFilterParamsDeps={numberFilterParamsDependencies}
        stringFilterParamsDeps={stringFilterParamsDependencies}
        getNumberFilterParams={handleGetNumberFilterParams}
        getStringFilterParams={handleGetStringFilterParams}
      >
        <QuickFiltersProvider
          activeFilters={filters}
          onChange={handleFiltersChange}
        >
          <QuickFilters />
        </QuickFiltersProvider>
        <Divider flexItem orientation="vertical" />
        <FiltersProvider activeFilters={filters} onChange={handleFiltersChange}>
          <FiltersDrawer />
        </FiltersProvider>
        <ResetAllButton
          hasFilters={filters.length > 0}
          onClick={handleResetAllClick}
        />
      </FiltersParamsProvider>
    </Box>
  );
}
