import { TextField } from '@mui/material';
import type { ChangeEvent, FocusEventHandler } from 'react';
import { forwardRef } from 'react';
import type { NumericFormatProps } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import type { InputComponentProps } from '../../types';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  min: number;
  max: number;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$"
      />
    );
  },
);

export function CurrencyInput({
  step,
  min,
  max,
  value,
  onChange,
}: InputComponentProps) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(+e.target.value);
  };
  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const inputValue = +event.target.value;
    const clampedValue = Math.max(min, Math.min(value, max));

    if (inputValue !== clampedValue) {
      onChange(clampedValue);
    }
  };

  return (
    <TextField
      size="small"
      InputProps={{
        inputComponent: NumericFormatCustom as any,
      }}
      variant="outlined"
      inputProps={{
        step,
        min,
        max,
      }}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
}
