import { FilterType } from './types';

export const isStringFilter = (type: FilterType): boolean => {
  return type === FilterType.TEXT;
};

const NUMBER_TYPES = new Set([
  FilterType.CURRENCY,
  FilterType.NUMBER,
  FilterType.PERCENTAGE,
]);

export const isNumberFilter = (type: FilterType): boolean => {
  return NUMBER_TYPES.has(type);
};

export const isMediaFilter = (type: FilterType): boolean => {
  return type === FilterType.MEDIA;
};
