import { Box, Button, Divider, MenuList, Typography } from '@mui/material';
import { useState } from 'react';
import type { NumberFilterParams, NumberFilterValue } from '../../model/types';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import { DEFAULT_PARAMS, NumberFilter } from '../NumberFilter/NumberFilter';

interface Props {
  id: string;
  value: NumberFilterValue;
  onChange: (v: NumberFilterValue) => void;
}

export function QuickNumberFilter({ id, value, onChange }: Props) {
  const { configsMap, paramsData } = useFiltersParamsContext();
  const [internalValue, setInternalValue] = useState<NumberFilterValue>(value);
  const params =
    paramsData[id] && paramsData[id].params
      ? (paramsData[id].params as NumberFilterParams)
      : undefined;
  const { name, type } = configsMap[id] || {};

  const handleClear = () => {
    setInternalValue([
      params?.min || DEFAULT_PARAMS.min,
      params?.max || DEFAULT_PARAMS.max,
    ]);
  };
  const handleApply = () => {
    onChange(internalValue);
  };

  return (
    <>
      <Box
        sx={{
          pt: 3,
          px: 3,
          pb: 1.25,
          display: 'flex',
          flexDirection: 'column',
          width: 384,
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            lineHeight: '22px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: 'var(--neutral-700)',
            mb: 2,
          }}
        >
          {name}
        </Typography>
        <MenuList>
          <NumberFilter
            id={id}
            type={type}
            value={internalValue}
            onChange={setInternalValue}
          />
        </MenuList>
      </Box>
      <Divider />
      <Box
        sx={{
          py: 2,
          px: 3,
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="text"
          onClick={handleClear}
          disabled={
            internalValue[0] === params?.min && internalValue[1] === params?.max
          }
        >
          Clear
        </Button>
        <Button size="small" variant="contained" onClick={handleApply}>
          Apply
        </Button>
      </Box>
    </>
  );
}
