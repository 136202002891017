import { Box, Divider } from '@mui/material';
import { Suspense, lazy, useCallback, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import type { DataConfig } from '@/widgets/cohortMarketingPerformance';
import {
  CohortMarketingPerformanceFilters,
  CohortMarketingPerformanceToolbar,
  selectConfig as selectCohortMarketingPerformanceConfig,
  resetConfig,
  selectConfigDimensions,
  selectConfigMetrics,
  updateConfig,
  selectMetaIsLoading,
  selectMetaIsFailed,
  updateSorting,
  reorderDimensions,
  reorderMetrics,
  selectConfigSorting,
  getPage,
  downloadData,
  selectMetaConfig,
  selectMetaIsLoaded,
  deleteConfigDimension,
  deleteConfigMetric,
  getMetricsMinMax,
  reset,
  selectConfigRowHeight,
  updateConfigColumnsWidth,
  selectConfigColumnsWidth,
  dataConfigValidator,
  selectMetaAllDimensions,
  selectMetaAllMetrics,
  selectDateScale,
  selectDateRange,
  selectLifetime,
  selectFilters,
  selectProbabilisticAttribution,
  selectAdvaceSortableOptions,
  selectConfigDataGridViewType,
  getTreeData,
  getTotalData,
} from '@/widgets/cohortMarketingPerformance';
import { Header } from '@/widgets/Header';
import { ProfileMenu } from '@/widgets/ProfileMenu';
import { DataGridViewType } from '@/features/DataGridTypeSwitcher';
import { DownloadDataButton } from '@/features/DownloadData';
import { RefreshData, useRefresher } from '@/features/RefreshData';
import { SharedViewType, ShareView } from '@/features/ShareView';
import { TimeMachine } from '@/features/TimeMachine';
import type { DataGroup } from '@/features/TreeDataGrid';
import { SavedViews, SavedViewStorage } from '@/entities/savedViews';
import { ROUTES } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import type { SortingItem } from '@/shared/types';
import { FatalError, Layout, Loader } from '@/shared/ui';

const DataTable = lazy(() =>
  import('@/features/DataTable').then(({ DataTable }) => {
    return { default: DataTable };
  }),
);
const TreeDataGrid = lazy(() =>
  import('@/features/TreeDataGrid').then(({ TreeDataGrid }) => {
    return { default: TreeDataGrid };
  }),
);

export function CohortMarketingPreformancePage() {
  const dispatch = useAppDispatch();
  const isColumnsMetaLoading = useAppSelector(selectMetaIsLoading);
  const isColumnsMetaLoaded = useAppSelector(selectMetaIsLoaded);
  const isColumnsMetaFailed = useAppSelector(selectMetaIsFailed);
  const metaColumnsConfig = useAppSelector(selectMetaConfig);
  const metrics = useAppSelector(selectConfigMetrics);
  const dimensions = useAppSelector(selectConfigDimensions);
  const sorting = useAppSelector(selectConfigSorting);
  const rowHeight = useAppSelector(selectConfigRowHeight);
  const config = useAppSelector(selectCohortMarketingPerformanceConfig);
  const allDimensions = useAppSelector(selectMetaAllDimensions);
  const allMetrics = useAppSelector(selectMetaAllMetrics);
  const dateScale = useAppSelector(selectDateScale);
  const dateRange = useAppSelector(selectDateRange);
  const lifetime = useAppSelector(selectLifetime);
  const filters = useAppSelector(selectFilters);
  const probabilisticAttribution = useAppSelector(
    selectProbabilisticAttribution,
  );
  const columnsWidth = useAppSelector(selectConfigColumnsWidth);
  const advaceSortableOptions = useAppSelector(selectAdvaceSortableOptions);
  const dataGridViewType = useAppSelector(selectConfigDataGridViewType);
  const viewToShare = useMemo(() => {
    return {
      type: SharedViewType.cohortMarketingPerformance,
      snapshot: config,
    };
  }, [config]);
  const { refresher, onRefresh } = useRefresher();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const applyConfig = (newConfig: unknown) => {
    dispatch(updateConfig(newConfig as DataConfig));
  };
  const handleTimeMachineReset = () => {
    dispatch(resetConfig());
  };
  const handleDownloadAllData = () => {
    return dispatch(
      downloadData({
        metrics,
        dimensions,
        dateScale,
        dateRange,
        sorting,
        probabilisticAttribution,
        filters,
        allMetrics,
        allDimensions,
        lifetime,
      }),
    );
  };
  const handleReorderDimensions = (info: {
    id: string;
    oldIndex: number;
    newIndex: number;
  }) => {
    dispatch(reorderDimensions(info));
  };
  const handleReorderMetrics = (info: {
    id: string;
    oldIndex: number;
    newIndex: number;
  }) => {
    dispatch(reorderMetrics(info));
  };
  const handleUpdateSorting = (column: string, newItem?: SortingItem) => {
    dispatch(updateSorting({ column, item: newItem }));
  };
  const handleDeleteDimension = (id: string) => {
    dispatch(deleteConfigDimension(id));
  };
  const handleDeleteMetric = (id: string) => {
    dispatch(deleteConfigMetric(id));
  };
  const handleGetMetricsMinMax = useCallback(
    () => {
      return dispatch(
        getMetricsMinMax({
          dimensions,
          metrics,
          filters,
          dateRange,
          dateScale,
          probabilisticAttribution,
          lifetime,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      dimensions,
      metrics,
      filters,
      dateRange,
      dateScale,
      probabilisticAttribution,
      lifetime,
      refresher,
    ],
  );
  const handleUpdateColumnsWidth = useCallback(
    (newColumnsWidth: Record<string, number | undefined>) => {
      dispatch(updateConfigColumnsWidth(newColumnsWidth));
    },
    [dispatch],
  );
  const handleGetTotalData = useCallback(
    () => {
      return dispatch(
        getTotalData({
          dimensions,
          metrics,
          dateRange,
          lifetime,
          filters,
          probabilisticAttribution,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      dimensions,
      metrics,
      dateRange,
      lifetime,
      filters,
      probabilisticAttribution,
      refresher,
    ],
  );
  const handleGetRowsPage = useCallback(
    (offset?: number) => {
      return dispatch(
        getPage({
          dimensions,
          metrics,
          dateScale,
          dateRange,
          sorting,
          probabilisticAttribution,
          lifetime,
          filters,
          offset,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      dimensions,
      metrics,
      dateScale,
      dateRange,
      sorting,
      probabilisticAttribution,
      lifetime,
      filters,
      refresher,
    ],
  );
  const handleGetTreeDataGridData = useCallback(
    (dataGroups: DataGroup[]) => {
      return dispatch(getTreeData(dataGroups));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      dimensions,
      metrics,
      dateScale,
      dateRange,
      lifetime,
      sorting,
      filters,
      probabilisticAttribution,
      refresher,
    ],
  );
  const isTreeView = dataGridViewType === DataGridViewType.treeDataGrid;

  return (
    <Layout
      headerSlot={
        <Header
          title="Cohort Marketing Performance"
          rightContentSlot={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                height: '100%',
              }}
            >
              {isColumnsMetaLoaded && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    height: 40,
                    color: 'white',
                  }}
                >
                  <TimeMachine
                    snapshot={config}
                    onTravel={applyConfig}
                    onReset={handleTimeMachineReset}
                  />
                  <RefreshData onRefresh={onRefresh} />
                  <Divider
                    sx={{ borderColor: 'currentColor' }}
                    orientation="vertical"
                  />
                  <DownloadDataButton
                    dimensions={dimensions}
                    metrics={metrics}
                    config={metaColumnsConfig}
                    getAllDataRows={handleDownloadAllData}
                  />
                  <ShareView
                    linkPrefix={ROUTES.cohortMarketingPerformance}
                    view={viewToShare}
                  />
                  <SavedViews
                    snapshotValidator={dataConfigValidator.safeParse}
                    storage={SavedViewStorage.cohortMarketingPerformance}
                    currentViewState={config}
                    onApply={applyConfig}
                  />
                </Box>
              )}
              <ProfileMenu />
            </Box>
          }
        />
      }
      slotProps={{
        content: {
          sx: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            py: 2,
            px: 5,
          },
        },
      }}
    >
      {isColumnsMetaFailed ? (
        <FatalError />
      ) : (
        <>
          <CohortMarketingPerformanceFilters />
          <CohortMarketingPerformanceToolbar />
          <Suspense fallback={<Loader active={!isColumnsMetaLoading} />}>
            {isTreeView ? (
              <TreeDataGrid
                columnsWidth={columnsWidth}
                columnsConfig={metaColumnsConfig}
                dimensions={dimensions}
                metrics={metrics}
                sorting={sorting}
                rowHeight={rowHeight}
                getMinMax={handleGetMetricsMinMax}
                updateSorting={handleUpdateSorting}
                deleteMetric={handleDeleteMetric}
                getData={handleGetTreeDataGridData}
                getTotal={handleGetTotalData}
                updateColumnsWidth={handleUpdateColumnsWidth}
              />
            ) : (
              <DataTable
                isColumnsConfigLoaded={isColumnsMetaLoaded}
                columnsWidth={columnsWidth}
                columnsConfig={metaColumnsConfig}
                dimensions={dimensions}
                metrics={metrics}
                sorting={sorting}
                rowHeight={rowHeight}
                dimensionsAdvancedSortingOptions={advaceSortableOptions}
                getMinMax={handleGetMetricsMinMax}
                getRowsPage={handleGetRowsPage}
                getTotal={handleGetTotalData}
                reorderDimensions={handleReorderDimensions}
                reorderMetrics={handleReorderMetrics}
                updateSorting={handleUpdateSorting}
                deleteDimension={handleDeleteDimension}
                deleteMetric={handleDeleteMetric}
                updateColumnsWidth={handleUpdateColumnsWidth}
              />
            )}
          </Suspense>
        </>
      )}
      <Loader active={isColumnsMetaLoading} />
      <Outlet />
    </Layout>
  );
}
