import { Box, Divider } from '@mui/material';
import { useMemo } from 'react';
import {
  getNumberFilterParams,
  getStringFilterParams,
  resetFilters,
  selectCohortDateRange,
  selectCohortDateScale,
  selectConfigDimensions,
  selectFilters,
  selectMetaAllDimensions,
  selectMetaAllMetrics,
  selectMetaConfig,
  selectProbabilisticAttribution,
  selectRevenueDateRange,
  selectRevenueDateScale,
  updateCohortDateRange,
  updateCohortDateScale,
  updateFilters,
  updateRevenueDateRange,
  updateRevenueDateScale,
} from '@/widgets/calendarInsights';
import type { Filters } from '@/features/filters';
import {
  FiltersDrawer,
  FiltersParamsProvider,
  FiltersProvider,
  QuickFilters,
  QuickFiltersProvider,
  ResetAllButton,
} from '@/features/filters';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import type { DateScale, SerializedDateRangeValueObject } from '@/shared/types';
import { DateRangeSelect, DateScaleSelect, Label } from '@/shared/ui';

export function CalendarInsightsFilters() {
  const dispatch = useAppDispatch();
  const columnsConfig = useAppSelector(selectMetaConfig);
  const allDimensions = useAppSelector(selectMetaAllDimensions);
  const allMetrics = useAppSelector(selectMetaAllMetrics);
  const cohortDateRange = useAppSelector(selectCohortDateRange);
  const cohortDateScale = useAppSelector(selectCohortDateScale);
  const revenueDateRange = useAppSelector(selectRevenueDateRange);
  const revenueDateScale = useAppSelector(selectRevenueDateScale);
  const filters = useAppSelector(selectFilters);
  const selectedDimensions = useAppSelector(selectConfigDimensions);
  const probabilisticAttribution = useAppSelector(
    selectProbabilisticAttribution,
  );
  const numberFilterParamsDependencies = useMemo(() => {
    return [
      selectedDimensions,
      filters,
      allMetrics,
      allDimensions,
      cohortDateRange,
      cohortDateScale,
      revenueDateRange,
      revenueDateScale,
      probabilisticAttribution,
    ];
  }, [
    selectedDimensions,
    filters,
    allMetrics,
    allDimensions,
    cohortDateRange,
    cohortDateScale,
    revenueDateRange,
    revenueDateScale,
    probabilisticAttribution,
  ]);
  const stringFilterParamsDependencies = useMemo(() => {
    return [
      filters,
      allMetrics,
      allDimensions,
      cohortDateRange,
      revenueDateRange,
    ];
  }, [filters, allMetrics, allDimensions, cohortDateRange, revenueDateRange]);

  const handleCohortDateScaleChange = (newScale: DateScale) => {
    dispatch(updateCohortDateScale(newScale));
  };
  const handleCohortDateRangeChange = (
    newDateRange: SerializedDateRangeValueObject,
  ) => {
    dispatch(updateCohortDateRange(newDateRange));
  };
  const handleRevenueDateScaleChange = (newScale: DateScale) => {
    dispatch(updateRevenueDateScale(newScale));
  };
  const handleRevenueDateRangeChange = (
    newDateRange: SerializedDateRangeValueObject,
  ) => {
    dispatch(updateRevenueDateRange(newDateRange));
  };
  const handleGetNumberFilterParams = (id: string) => {
    return dispatch(
      getNumberFilterParams({
        filterName: id,
        selectedDimensions,
        filters,
        allMetrics,
        allDimensions,
        cohortDateRange,
        cohortDateScale,
        revenueDateRange,
        revenueDateScale,
        probabilisticAttribution,
      }),
    );
  };
  const handleGetStringFilterParams = (id: string) => {
    return dispatch(
      getStringFilterParams({
        filterName: id,
        filters,
        allMetrics,
        allDimensions,
        cohortDateRange,
        revenueDateRange,
      }),
    );
  };
  const handleFiltersChange = (newFilters: Filters) => {
    dispatch(updateFilters(newFilters));
  };
  const handleResetAllClick = () => {
    dispatch(resetFilters());
  };

  return (
    <Box display="flex" gap={3}>
      <Label text="Date">
        <Box display="flex" gap={1.25}>
          <DateScaleSelect
            value={revenueDateScale}
            onChange={handleRevenueDateScaleChange}
          />
          <DateRangeSelect
            withFuture
            value={revenueDateRange}
            onChange={handleRevenueDateRangeChange}
          />
        </Box>
      </Label>
      <Label text="Cohort Date">
        <Box display="flex" gap={1.25}>
          <DateScaleSelect
            value={cohortDateScale}
            onChange={handleCohortDateScaleChange}
          />
          <DateRangeSelect
            value={cohortDateRange}
            onChange={handleCohortDateRangeChange}
          />
        </Box>
      </Label>
      <Box flexGrow={1} />
      <Box
        sx={{
          alignSelf: 'flex-end',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 3,
        }}
      >
        <FiltersParamsProvider
          allDimensions={allDimensions}
          allMetrics={allMetrics}
          columnsConfig={columnsConfig}
          numberFilterParamsDeps={numberFilterParamsDependencies}
          stringFilterParamsDeps={stringFilterParamsDependencies}
          getNumberFilterParams={handleGetNumberFilterParams}
          getStringFilterParams={handleGetStringFilterParams}
        >
          <QuickFiltersProvider
            activeFilters={filters}
            onChange={handleFiltersChange}
          >
            <QuickFilters sx={{ maxWidth: '100%' }} />
          </QuickFiltersProvider>
          <Divider flexItem orientation="vertical" />
          <FiltersProvider
            activeFilters={filters}
            onChange={handleFiltersChange}
          >
            <FiltersDrawer />
          </FiltersProvider>
          <ResetAllButton
            hasFilters={filters.length > 0}
            onClick={handleResetAllClick}
          />
        </FiltersParamsProvider>
      </Box>
    </Box>
  );
}
