import { baseApi, getErrorMessage } from '@/shared/api';
import type { MetaRegistry } from '../model/types';
import type {
  CalendarDataResponse,
  CohortDataResponse,
  GetCalendarChartNumberFilterParams,
  GetCalendarChartStringFilterParams,
  GetCalendarDataParams,
  GetCohortChartNumberFilterParams,
  GetCohortChartStringFilterParams,
  GetCohortDataParams,
  MetaDTO,
} from './types';

export const getMeta = async (): Promise<MetaRegistry> => {
  const { data, error } = await baseApi.functions.invoke<MetaDTO>(
    'getComparisonViewMeta',
  );

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return data;
};

export const getCohortData = async ({
  split,
  metrics,
  dateScale,
  dateRange,
  probabilisticAttribution,
  lifetime,
  dimensionsFilters,
  metricsFilters,
}: GetCohortDataParams) => {
  const { data, error } = await baseApi.functions.invoke<CohortDataResponse>(
    'getComparisonViewCohortData',
    {
      body: {
        split,
        metrics,
        dateScale,
        dateRange,
        probabilisticAttribution,
        lifetime,
        dimensionsFilters:
          dimensionsFilters.length > 0 ? dimensionsFilters : null,
        metricsFilters: metricsFilters.length > 0 ? metricsFilters : null,
      },
    },
  );

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return data;
};

export const getCalendarData = async ({
  split,
  metrics,
  cohortDateScale,
  cohortDateRange,
  revenueDateScale,
  revenueDateRange,
  probabilisticAttribution,
  dimensionsFilters,
  metricsFilters,
}: GetCalendarDataParams) => {
  const { data, error } = await baseApi.functions.invoke<CalendarDataResponse>(
    'getComparisonViewCalendarData',
    {
      body: {
        split,
        metrics,
        cohortDateScale,
        cohortDateRange,
        revenueDateScale,
        revenueDateRange,
        probabilisticAttribution,
        dimensionsFilters:
          dimensionsFilters.length > 0 ? dimensionsFilters : null,
        metricsFilters: metricsFilters.length > 0 ? metricsFilters : null,
      },
    },
  );

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return data;
};

export const getCohortChartNumberFilterParams = async (
  params: GetCohortChartNumberFilterParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    min: number;
    max: number;
  }>('getNumberFilterParams', {
    body: {
      filterName: params.filterName,
      selectedDimensions: params.selectedDimensions,
      dimensionsFilters: params.dimensionsFilters.length
        ? params.dimensionsFilters
        : null,
      metricsFilters: params.metricsFilters.length
        ? params.metricsFilters
        : null,
      dateRange: params.dateRange,
      dateScale: params.dateScale,
      lifetime: params.lifetime,
      probabilisticAttribution: params.probabilisticAttribution,
    },
  });

  if (!data) {
    throw new Error(getErrorMessage(error));
  }

  return data;
};

export const getCohortChartStringFilterParams = async (
  params: GetCohortChartStringFilterParams,
) => {
  const filtersWithoutCurrent = params.dimensionsFilters.filter((filter) => {
    return filter.id !== params.filterName;
  });

  const { data, error } = await baseApi.functions.invoke<{
    options: string[];
  }>('getStringFilterParams', {
    body: {
      filterName: params.filterName,
      dimensionsFilters: filtersWithoutCurrent.length
        ? filtersWithoutCurrent
        : null,
      dateRange: params.dateRange,
      orderMetric: '',
      sort: null,
    },
  });

  if (!data) {
    throw new Error(getErrorMessage(error));
  }

  return data;
};

export const getCalendarChartNumberFilterParams = async (
  params: GetCalendarChartNumberFilterParams,
) => {
  const { data, error } = await baseApi.functions.invoke<{
    min: number;
    max: number;
  }>('getCalendarInsightsNumberFilterParams', {
    body: {
      filterName: params.filterName,
      selectedDimensions: params.selectedDimensions,
      dimensionsFilters: params.dimensionsFilters.length
        ? params.dimensionsFilters
        : null,
      metricsFilters: params.metricsFilters.length
        ? params.metricsFilters
        : null,
      cohortDateRange: params.cohortDateRange,
      cohortDateScale: params.cohortDateScale,
      revenueDateRange: params.revenueDateRange,
      revenueDateScale: params.revenueDateScale,
      probabilisticAttribution: params.probabilisticAttribution,
    },
  });

  if (!data) {
    throw new Error(getErrorMessage(error));
  }

  return data;
};

export const getCalendarChartStringFilterParams = async (
  params: GetCalendarChartStringFilterParams,
) => {
  const filtersWithoutCurrent = params.dimensionsFilters.filter((filter) => {
    return filter.id !== params.filterName;
  });

  const { data, error } = await baseApi.functions.invoke<{
    options: string[];
  }>('getCalendarInsightsStringFilterParams', {
    body: {
      filterName: params.filterName,
      dimensionsFilters: filtersWithoutCurrent.length
        ? filtersWithoutCurrent
        : null,
      cohortDateRange: params.cohortDateRange,
      revenueDateRange: params.revenueDateRange,
      orderMetric: '',
      sort: null,
    },
  });

  if (!data) {
    throw new Error(getErrorMessage(error));
  }

  return data;
};
