import { Backdrop } from '@mui/material';
import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {
  selectIsAuthorized,
  selectIsSessionLoading,
  selectIsSessionRequested,
  setupUser,
} from '@/entities/session';
import { ROUTES } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Spinner } from '@/shared/ui';

export function AuthGuard() {
  const dispath = useAppDispatch();
  const isSessionRequested = useAppSelector(selectIsSessionRequested);
  const isSessionLoading = useAppSelector(selectIsSessionLoading);
  const isAuthorized = useAppSelector(selectIsAuthorized);

  useEffect(() => {
    if (isSessionRequested) {
      return;
    }

    dispath(setupUser());
  }, [isSessionRequested, dispath]);

  if (isSessionLoading || !isSessionRequested) {
    return (
      <Backdrop open>
        <Spinner />
      </Backdrop>
    );
  } else if (!isAuthorized) {
    return <Navigate to={ROUTES.signin} />;
  }

  return <Outlet />;
}
