import { OutlinedInput } from '@mui/material';
import {
  useEffect,
  useState,
  type ChangeEventHandler,
  type FocusEventHandler,
} from 'react';
import type { InputComponentProps } from '../../types';

export function NumberInput({
  step,
  min,
  max,
  value,
  onChange,
}: InputComponentProps) {
  const [internalValue, setInternalValue] = useState<string>(`${value}`);

  useEffect(() => {
    if (value === 0 && internalValue === '') {
      return;
    }

    setInternalValue(`${value}`);
  }, [value, internalValue]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInternalValue(event.target.value);
    onChange(+event.target.value);
  };
  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const inputValue = +event.target.value;
    const clampedValue = Math.max(min, Math.min(value, max));

    if (inputValue !== clampedValue) {
      onChange(clampedValue);
    }
  };

  return (
    <OutlinedInput
      sx={{
        flexGrow: 1,
      }}
      size="small"
      type="number"
      inputProps={{
        step,
        min,
        max,
      }}
      value={internalValue}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
}
