import { Box, Divider } from '@mui/material';
import {
  selectConfigDataGridViewType,
  selectConfigDimensions,
  selectConfigMetrics,
  selectConfigRowHeight,
  selectMetaAllDimensions,
  selectMetaAllMetrics,
  selectMetaConfig,
  selectMetaIsLoading,
  selectProbabilisticAttribution,
  setupColumnsMeta,
  updateColumns,
  updateConfigRowHeight,
  updateDataGridViewType,
  updateProbabilisticAttribution,
} from '@/widgets/calendarInsights';
import { AddColumns } from '@/features/AddColumns';
import type { DataGridViewType } from '@/features/DataGridTypeSwitcher';
import { DataGridTypeSwitcher } from '@/features/DataGridTypeSwitcher';
import { ProbabilisticAttributionSwitcher } from '@/features/ProbabilisticAttribution';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { DensitySwitcher } from '@/shared/ui';

export function CalendarInsightsToolbar() {
  const dispatch = useAppDispatch();
  const probabilisticAttribution = useAppSelector(
    selectProbabilisticAttribution,
  );
  const isColumnsConfigLoading = useAppSelector(selectMetaIsLoading);
  const selectedDimensions = useAppSelector(selectConfigDimensions);
  const selectedMetrics = useAppSelector(selectConfigMetrics);
  const allDimensions = useAppSelector(selectMetaAllDimensions);
  const allMetrics = useAppSelector(selectMetaAllMetrics);
  const columnsConfig = useAppSelector(selectMetaConfig);
  const rowHeight = useAppSelector(selectConfigRowHeight);
  const dataGridViewType = useAppSelector(selectConfigDataGridViewType);

  const handleColumnsConfigRequest = () => {
    dispatch(setupColumnsMeta());
  };
  const handleRowHeightChange = (size: number) => {
    dispatch(updateConfigRowHeight(size));
  };
  const handleColumnsUpdate = (newColumns: {
    dimensions: string[];
    metrics: string[];
  }) => {
    dispatch(updateColumns(newColumns));
  };
  const handleProbabilisticAttributionChange = (checked: boolean) => {
    dispatch(updateProbabilisticAttribution(checked));
  };
  const handleDataGridViewTypeChange = (newViewType: DataGridViewType) => {
    dispatch(updateDataGridViewType(newViewType));
  };

  return (
    <Box display="flex" gap={2}>
      <AddColumns
        isColumnsConfigLoading={isColumnsConfigLoading}
        selectedDimensions={selectedDimensions}
        selectedMetrics={selectedMetrics}
        allDimensions={allDimensions}
        allMetrics={allMetrics}
        columnsConfig={columnsConfig}
        requestColumnsConfig={handleColumnsConfigRequest}
        updateColumns={handleColumnsUpdate}
      />
      <Divider flexItem orientation="vertical" />
      <ProbabilisticAttributionSwitcher
        value={probabilisticAttribution}
        onChange={handleProbabilisticAttributionChange}
      />
      <Box flexGrow={1} />
      <DataGridTypeSwitcher
        isTreeViewAvailable={selectedDimensions.length > 1}
        value={dataGridViewType}
        onChange={handleDataGridViewTypeChange}
      />
      <DensitySwitcher value={rowHeight} onChange={handleRowHeightChange} />
    </Box>
  );
}
