import { LicenseInfo } from '@mui/x-license';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { isDevelopment } from '@/shared/utils';
import { appRouter } from './routing';
import { store } from './store/store';
import './main.css';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY);

if (!isDevelopment()) {
  Sentry.init({
    dsn: 'https://87ac28832cdf535d7b93566cf53e7d34@o44050.ingest.us.sentry.io/4507327644172288',
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={appRouter()} />
      </Provider>
    </React.StrictMode>,
  );
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  );
}
