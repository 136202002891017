import type { FilterConfig } from '@/features/filters';
import { FilterType } from '@/features/filters';
import type { ColumnDataType } from '@/entities/columnsConfig';
import {
  DimensionColumnDataType,
  MetricColumnDataType,
} from '@/entities/columnsConfig';
import type { ColumnsConfig } from '../../model/types';

const ALLOWED_FILTERS: Set<ColumnDataType> = new Set([
  MetricColumnDataType.CURRENCY,
  MetricColumnDataType.NUMBER,
  MetricColumnDataType.PERCENTAGE,
  DimensionColumnDataType.TEXT,
  DimensionColumnDataType.MEDIA,
]);

interface Params {
  columnsConfig: ColumnsConfig;
  allDimensions: string[];
  allMetrics: string[];
}

export const useFilterConfigs = ({
  columnsConfig,
  allDimensions,
  allMetrics,
}: Params): FilterConfig[] => {
  const filters = new Set([...allMetrics, ...allDimensions]);

  return [...filters]
    .filter((filterId) => {
      return ALLOWED_FILTERS.has(columnsConfig[filterId].type);
    })
    .map((filterId) => {
      const { group, name, type, order } = columnsConfig[filterId];

      return {
        id: filterId,
        name,
        group,
        type: transformColumnDataType(type),
        order,
      };
    });
};

const transformColumnDataType = (type: ColumnDataType): FilterType => {
  switch (type) {
    case MetricColumnDataType.CURRENCY:
      return FilterType.CURRENCY;
    case MetricColumnDataType.NUMBER:
      return FilterType.NUMBER;
    case MetricColumnDataType.PERCENTAGE:
      return FilterType.PERCENTAGE;
    case DimensionColumnDataType.TEXT:
      return FilterType.TEXT;
    case DimensionColumnDataType.MEDIA:
      return FilterType.MEDIA;
    default:
      return FilterType.UNKNOWN;
  }
};
